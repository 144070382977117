import {API_ADMIN_TOKEN, API_URL} from './config';


/**
 * Функция для загрузки всех точек
 * @returns {Promise<Object>} - GeoJSON FeatureCollection
 */
export async function fetchAllPoints() {

    const url = `${API_URL}/api/points/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${API_ADMIN_TOKEN}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Ошибка сети: ${response.status} ${response.statusText}`);
        }

        const points = await response.json(); // Массив фич

        return {
            type: 'FeatureCollection',
            features: points,
        };
    } catch (error) {
        console.error('Ошибка при загрузке точек:', error);
        return {
            type: 'FeatureCollection',
            features: [],
        };
    }
}


// Функция для загрузки конкретной точки
export async function populatePoint(pointId) {
    try {
        const response = await fetch(`${API_URL}/api/points/${pointId}/`);

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        const point = await response.json();

        // Заполнение полей информации о точке
        document.getElementById('point-name').value = point.properties.name;
        document.getElementById('point-description').value = point.properties.description;

        // Заполнение категории и подкатегории
        document.getElementById('point-category').value = point.properties.category;
        document.getElementById('point-subcategory').value = point.properties.subcategory;

        // Заполнение контактной информации
        const contactInfo = point.properties.contact_info;
        if (contactInfo) {
            document.getElementById('contact-phone').value = contactInfo.phone_numbers.join(', ');
            document.getElementById('whatsapp-urls').value = contactInfo.whatsapp_urls.join(', ');
            document.getElementById('viber-urls').value = contactInfo.viber_urls.join(', ');
            document.getElementById('telegram-urls').value = contactInfo.telegram_urls.join(', ');
            document.getElementById('line-urls').value = contactInfo.line_urls.join(', ');
            document.getElementById('websites').value = contactInfo.websites.join(', ');
        }

        // Заполнение рабочего времени
        const workingHours = point.properties.working_hours;
        if (workingHours) {
            document.getElementById('monday-open').value = workingHours.monday_open;
            document.getElementById('monday-close').value = workingHours.monday_close;
            document.getElementById('tuesday-open').value = workingHours.tuesday_open;
            document.getElementById('tuesday-close').value = workingHours.tuesday_close;
            document.getElementById('wednesday-open').value = workingHours.wednesday_open;
            document.getElementById('wednesday-close').value = workingHours.wednesday_close;
            document.getElementById('thursday-open').value = workingHours.thursday_open;
            document.getElementById('thursday-close').value = workingHours.thursday_close;
            document.getElementById('friday-open').value = workingHours.friday_open;
            document.getElementById('friday-close').value = workingHours.friday_close;
            document.getElementById('saturday-open').value = workingHours.saturday_open || '';
            document.getElementById('saturday-close').value = workingHours.saturday_close || '';
            document.getElementById('sunday-open').value = workingHours.sunday_open || '';
            document.getElementById('sunday-close').value = workingHours.sunday_close || '';
        }

        const priceList = point.properties.price_list.products;
        const priceListContainer = document.getElementById('price-list');
        priceListContainer.innerHTML = '';

        priceList.forEach(product => {
            const productDiv = document.createElement('div');
            productDiv.innerHTML = `
                <p>Название продукта: ${product.product_name}</p>
                <p>Цена: ${product.price} ${product.currency}</p>
                <p>Количество: ${product.quantity}</p>
            `;
            priceListContainer.appendChild(productDiv);
        });

        const pointImage = document.getElementById('point-image');
        pointImage.src = point.properties.image;

    } catch (error) {
        console.error('Ошибка загрузки точки:', error);
    }
}


// Функция для создания Point'а
export async function createPoint(pointData) {

    const url = `${API_URL}/api/points/`;

    // Создаем объект точки, который нужно отправить
    const pointPayload = {
        properties: {
            name: pointData.name,
            description: pointData.description,
            address: pointData.address,
            marker_type: pointData.markerType, // ID типа маркера
            category: pointData.category,
            subcategory: pointData.subcategory,
            contact_info: {
                phone_numbers: pointData.contactInfo.phoneNumbers || [],
                whatsapp_urls: pointData.contactInfo.whatsappUrls || [],
                viber_urls: pointData.contactInfo.viberUrls || [],
                telegram_urls: pointData.contactInfo.telegramUrls || [],
                line_urls: pointData.contactInfo.lineUrls || [],
                websites: pointData.contactInfo.websites || [],
            },
            working_hours: {
                monday_open: pointData.workingHours.mondayOpen || null,
                monday_close: pointData.workingHours.mondayClose || null,
                tuesday_open: pointData.workingHours.tuesdayOpen || null,
                tuesday_close: pointData.workingHours.tuesdayClose || null,
                wednesday_open: pointData.workingHours.wednesdayOpen || null,
                wednesday_close: pointData.workingHours.wednesdayClose || null,
                thursday_open: pointData.workingHours.thursdayOpen || null,
                thursday_close: pointData.workingHours.thursdayClose || null,
                friday_open: pointData.workingHours.fridayOpen || null,
                friday_close: pointData.workingHours.fridayClose || null,
                saturday_open: pointData.workingHours.saturdayOpen || null,
                saturday_close: pointData.workingHours.saturdayClose || null,
                sunday_open: pointData.workingHours.sundayOpen || null,
                sunday_close: pointData.workingHours.sundayClose || null
            },
            price_list: pointData.priceList,
            tags: pointData.tags,
            zoom: pointData.zoom
        },
        geometry: {
            type: "Point",
            coordinates: pointData.coordinates
        }
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${API_ADMIN_TOKEN}`
            },
            body: JSON.stringify(pointPayload)
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }

        const responseData = await response.json();
        const pointId = responseData.properties.id;
        if (pointData.logo || pointData.images.length > 0) {
            const formData = new FormData();

            if (pointData.logo) {
                formData.append('logo', pointData.logo);
            }

            if (pointData.images.length > 0) {
                pointData.images.forEach(image => {
                    formData.append('images', image); // здесь добавляем просто сам файл
                });
            }

            const fileResponse = await fetch(`${url}${pointId}/`, {
                method: 'PUT', // Используем PATCH для обновления
                headers: {
                    'Authorization': `Token ${API_ADMIN_TOKEN}`,
                },
                body: formData
            });

            if (!fileResponse.ok) {
                throw new Error('Ошибка при загрузке файлов: ' + fileResponse.statusText);
            }

            const fileResponseData = await fileResponse.json();
            console.log('Файлы успешно загружены:', fileResponseData);
        }

    } catch (error) {
        console.error('Ошибка при создании события:', error);
    }
}

/**
 * Функция для обновления точки
 * @param {number|string} id - ID точки для обновления
 * @param {Object} pointData - Данные точки для обновления
 * @returns {Promise<Object>} - Обновленная точка
 */
export async function updatePoint(id, pointData) {

    const url = `${API_URL}/api/points/${id}/`;

    const pointPayload = {
        properties: {
            name: pointData.name,
            description: pointData.description,
            address: pointData.address,
            marker_type: pointData.markerType,
            category: pointData.category,
            subcategory: pointData.subcategory,
            contact_info: {
                phone_numbers: pointData.contactInfo.phoneNumbers || [],
                whatsapp_urls: pointData.contactInfo.whatsappUrls || [],
                viber_urls: pointData.contactInfo.viberUrls || [],
                telegram_urls: pointData.contactInfo.telegramUrls || [],
                line_urls: pointData.contactInfo.lineUrls || [],
                websites: pointData.contactInfo.websites || [],
            },
            working_hours: {
                monday_open: pointData.workingHours.mondayOpen || null,
                monday_close: pointData.workingHours.mondayClose || null,
                tuesday_open: pointData.workingHours.tuesdayOpen || null,
                tuesday_close: pointData.workingHours.tuesdayClose || null,
                wednesday_open: pointData.workingHours.wednesdayOpen || null,
                wednesday_close: pointData.workingHours.wednesdayClose || null,
                thursday_open: pointData.workingHours.thursdayOpen || null,
                thursday_close: pointData.workingHours.thursdayClose || null,
                friday_open: pointData.workingHours.fridayOpen || null,
                friday_close: pointData.workingHours.fridayClose || null,
                saturday_open: pointData.workingHours.saturdayOpen || null,
                saturday_close: pointData.workingHours.saturdayClose || null,
                sunday_open: pointData.workingHours.sundayOpen || null,
                sunday_close: pointData.workingHours.sundayClose || null
            },
            price_list: pointData.priceList,
            tags: pointData.tags,
            zoom: pointData.zoom
        },
        geometry: {
            type: "Point",
            coordinates: pointData.coordinates
        }
    };

    try {
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${API_ADMIN_TOKEN}`
            },
            body: JSON.stringify(pointPayload)
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }

        const responseData = await response.json();
        const pointId = responseData.properties.id;

        if (pointData.logo || pointData.images.length > 0) {
            const formData = new FormData();

            if (pointData.logo) {
                formData.append('logo', pointData.logo);
            }

            if (pointData.images.length > 0) {
                pointData.images.forEach(image => {
                    formData.append('images', image);
                });
            }

            const fileResponse = await fetch(`${API_URL}/api/points/${pointId}/`, {
                method: 'PUT', // Используем PATCH для загрузки файлов
                headers: {
                    'Authorization': `Token ${API_ADMIN_TOKEN}`,
                },
                body: formData
            });

            if (!fileResponse.ok) {
                throw new Error('Ошибка при загрузке файлов: ' + fileResponse.statusText);
            }

            const fileResponseData = await fileResponse.json();
            console.log('Файлы успешно загружены:', fileResponseData);
        }

        return responseData; // Возвращаем обновленную точку

    } catch (error) {
        console.error('Ошибка при обновлении точки:', error);
        throw error;
    }
}

/**
 * Функция для удаления точки
 * @param {number|string} id - ID точки для удаления
 */
export async function deletePoint(id) {

    const url = `${API_URL}/api/points/${id}/`;

    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${API_ADMIN_TOKEN}`,
            },
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }
        console.log(`Точка с ID ${id} удалена`);
    } catch (error) {
        console.error(`Ошибка удаления Точки ${id}:`, error);
        throw error;
    }
}