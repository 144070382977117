export function createEmptyEvent() {
    return {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [0, 0] // Можно установить как центр карты или другие координаты
        },
        properties: {
            id: null,
            name: "",
            description: "",
            address: "",
            contact_number: "",
            whatsapp: "",
            viber: "",
            telegram: "",
            line: "",
            event_type: "",
            event_start: "",
            event_end: "",
            website: "",
            tags: [],
            notify_users: false,
            image: "",
            category: null,
            subcategory: null
        }
    };
}
