import {generateUniqueId} from './utils';
import {placeLayerId, updatePlacesLayerData} from "./layers";
import {addNewPlaceSingle, loadPlacesData} from "./data";
import {showSidebar, loadDefaultSidebarContent, setCurrentFeature} from './../index'; // Импортируем функцию openEditForm


// Добавление обработчика клика по карте для создания новых точек
export function setupMapClickEvent(map) {

    map.on('click', async (e) => {

        const features = map.queryRenderedFeatures(e.point, {layers: [placeLayerId]});
        if (!features.length) {
            try {
                const lngLat = e.lngLat;
                const coords = [lngLat.lng, lngLat.lat];
                const generatedId = generateUniqueId();

                const newFeature = {
                    type: 'Feature',
                    properties: {
                        id: null,
                        frontId: generatedId,
                        description: `Новый маркер ${generatedId}`,
                        // TODO оставить один
                        'marker-type': 'custom-marker',
                        markerType: 'custom-marker',
                        marker_type: 'custom-marker',
                        // TODO мб как то по другому, в index в Point ошибка летела, ща вроде норм
                        // contact_info: {
                        //     phone_numbers: [],
                        //     whatsapp_urls: [],
                        //     viber_urls: [],
                        //     telegram_urls: [],
                        //     line_urls: [],
                        //     websites: [],
                        // },
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: coords
                    }
                };

                loadDefaultSidebarContent()
                setCurrentFeature(newFeature)
                showSidebar()

                // TODO мб тут еще делать const data = modules.getSource('places')._data;
                // TODO ваще тут не надо заново подгружать, надо брать глобальную переменную
                const placesData = await loadPlacesData();
                // Добавляем новую точку в данные
                const newPlacesData = addNewPlaceSingle(placesData, newFeature);
                // Обновляем слой на карте
                updatePlacesLayerData(map, newPlacesData);

            } catch (error) {
                console.error('Ошибка при добавлении нового маркера:', error);
            }
        }
    });
}
