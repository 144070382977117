import 'bootstrap/dist/css/bootstrap.css'; // Используйте минифицированную версию для продакшна
import 'bootstrap/dist/js/bootstrap.bundle.js'; // Используйте минифицированную версию для продакшна
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'; // Импорт стилей Mapbox GL JS
import {mapConfig} from './modules/config';
import {addFullscreenControl, addNavigationControl, addScaleControl} from './modules/controls';
import {addNewPlaces, addNewPlaceSingle, getDefaultPlacesData, loadPlacesData} from './modules/data';
import {addPlacesLayer, updatePlacesLayerData} from './modules/layers';
import {addInteractiveFunctions, showEditForm} from './modules/interactive';
import {setupMapClickEvent} from './modules/events';
import {loadAllMapImages} from './modules/images';
import {createEvent, populateEventTypes} from './modules/api/events';
import {populateTags} from './modules/api/tags';
import {populateCategories} from './modules/api/categories';
import {createEmptyEvent} from "./modules/data/events";
import {createPoint, updatePoint, deletePoint} from "./modules/api/points";
import {API_URL} from "./modules/api/config";

// Инициализация карты
mapboxgl.accessToken = mapConfig.accessToken;
const map = new mapboxgl.Map({
    container: mapConfig.containerId,
    style: mapConfig.styleId,
    center: mapConfig.center,
    zoom: mapConfig.zoom
});

// Хранение данных карты в глобальной переменной для доступа из функций
let placesData = {features: []};
export let currentFeature = null;

export function setCurrentFeature(feature) {
    currentFeature = feature;
}

// Функция для установки контента в сайдбар
function setSidebarContent(contentHTML) {
    const sidebarContent = document.getElementById('sidebar-content');
    if (sidebarContent) {
        sidebarContent.innerHTML = contentHTML;
    }
}

// Функция для отображения сайдбара
export function showSidebar() {
    const sidebar = document.getElementById('sidebar');
    const toggleBtn = document.getElementById('toggle-sidebar');
    const adminToggleBtn = document.getElementById('admin-toggle-btn');
    if (sidebar && toggleBtn && adminToggleBtn) {
        sidebar.classList.remove('sidebar-hide');
        sidebar.classList.add('sidebar-show');
        toggleBtn.classList.remove('toggle-visible');
        toggleBtn.classList.add('toggle-hidden');
        adminToggleBtn.classList.remove('toggle-visible');
        adminToggleBtn.classList.add('toggle-hidden');
    }
}

// Функция для скрытия сайдбара
export function hideSidebar() {
    const sidebar = document.getElementById('sidebar');
    const toggleBtn = document.getElementById('toggle-sidebar');
    const adminToggleBtn = document.getElementById('admin-toggle-btn');
    if (sidebar && toggleBtn && adminToggleBtn) {
        sidebar.classList.remove('sidebar-show');
        sidebar.classList.add('sidebar-hide');
        toggleBtn.classList.remove('toggle-hidden');
        toggleBtn.classList.add('toggle-visible');
        adminToggleBtn.classList.remove('toggle-hidden');
        adminToggleBtn.classList.add('toggle-visible');
    }
}

// Функция для загрузки главного меню
function loadMainMenu() {
    const mainMenuHTML = `
        <h2>Главное меню</h2>
        <div class="main-menu">
            <button id="categories-btn" class="btn btn-secondary">
                <i class="bi bi-tags"></i> Категории
            </button>
<!--            TODO потом засунуть при клике в первое меню-->
<!--            <button id="create-event-btn" class="btn btn-secondary">-->
<!--                <i class="bi bi-calendar-plus"></i> Создание событий-->
<!--            </button>-->
        </div>
    `;
    setSidebarContent(mainMenuHTML);
    // showSidebar();
}

// Функция для загрузки категорий меню
async function loadCategoriesMenu() {
    console.log("loadCategoriesMenu вызвана");

    // Загружаем категории с сервера
    const categories = await populateCategories();

    // Генерируем HTML для меню категорий
    let categoriesHTML = '<h2>Категории</h2><div class="categories-menu">';

    categories.forEach(category => {
        categoriesHTML += `
            <button class="btn btn-secondary category-btn" data-category="${category.id}">
                <i class="bi bi-tag-fill"></i> ${category.name}
            </button>
        `;
    });

    categoriesHTML += '</div>';

    // Вставляем HTML в боковую панель
    setSidebarContent(categoriesHTML);
    showSidebar();

    // Добавляем обработчики событий для кнопок категорий
    const categoryButtons = document.querySelectorAll('.category-btn');
    categoryButtons.forEach(button => {
        button.addEventListener('click', () => {
            const categoryId = button.getAttribute('data-category');
            console.log(`Категория выбрана: ${categoryId}`); // Отладочное сообщение
            loadSubCategories(categoryId, categories);
        });
    });
}

// Функция для загрузки подкатегорий
async function loadSubCategories(categoryId, categories) {
    console.log(`Загрузка подкатегорий для категории: ${categoryId}`);

    // Находим нужную категорию по её ID
    const selectedCategory = categories.find(category => category.id === parseInt(categoryId));

    if (!selectedCategory) {
        console.error('Категория не найдена');
        return;
    }

    // Проверяем наличие подкатегорий
    if (selectedCategory.subcategories.length === 0) {
        console.warn('Нет подкатегорий для выбранной категории');
        setSidebarContent(`<p>Нет подкатегорий для выбранной категории.</p>`);
        return;
    }

    // Генерация HTML для подкатегорий
    let subcategoriesHTML = `<h2>Подкатегории для ${selectedCategory.name}</h2><div class="subcategories-menu">`;

    selectedCategory.subcategories.forEach(subcategory => {
        subcategoriesHTML += `
            <button class="btn btn-secondary subcategory-btn" data-subcategory="${subcategory.id}">
                <i class="bi bi-tag-fill"></i> ${subcategory.name}
            </button>
        `;
    });

    subcategoriesHTML += '</div>';

    // Вставляем HTML в боковую панель
    setSidebarContent(subcategoriesHTML);
    showSidebar();

    // Добавляем обработчики событий для кнопок подкатегорий
    const subcategoryButtons = document.querySelectorAll('.subcategory-btn');
    subcategoryButtons.forEach(button => {
        button.addEventListener('click', () => {
            const subcategoryId = button.getAttribute('data-subcategory');
            console.log(`Подкатегория выбрана: ${subcategoryId}`); // Отладочное сообщение
            currentFeature.properties.category = categoryId;
            currentFeature.properties.subcategory = subcategoryId;
            openEditPoint(currentFeature)
        });
    });
}

// TODO удалить и объединить в одну форму
// Экспортируем функцию openEditEvent для использования в interactive.js
export async function openEditEvent(feature) {
    const formHTML = `
        <div class="container">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <h3>Редактировать событие</h3>
            </div>
            <form id="edit-marker-form" enctype="multipart/form-data">
                <div class="mb-3">
                    <label for="event-name" class="form-label">Название события</label>
                    <input type="text" class="form-control" id="event-name" required placeholder="Введите название события" 
                        value="${feature.properties.name || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="logo" class="form-label">Логотип события</label>
                    <input type="file" class="form-control" id="logo">
                </div>
                <div class="mb-3">
                    <label for="images" class="form-label">Изображения события</label>
                    <input type="file" class="form-control" id="images" multiple>
                </div>
                <div class="mb-3">
                    <label for="marker-description" class="form-label">Описание</label>
                    <textarea class="form-control" id="marker-description" rows="3">${feature.properties.description || ''}</textarea>
                </div>
                <div class="mb-3">
                    <label for="address" class="form-label">Адрес</label>
                    <input type="text" class="form-control" id="address" placeholder="Введите адрес" value="${feature.properties.address || ''}">
                </div>
                <div class="mb-3">
                    <label for="contact-number" class="form-label">Контактный номер</label>
                    <input type="text" class="form-control" id="contact-number" placeholder="Введите контактный номер" value="${feature.properties.contact_number || ''}">
                </div>
                <div class="mb-3">
                    <label for="whatsapp" class="form-label">WhatsApp</label>
                    <input type="text" class="form-control" id="whatsapp" placeholder="Введите WhatsApp" value="${feature.properties.whatsapp || ''}">
                </div>
                <div class="mb-3">
                    <label for="viber" class="form-label">Viber</label>
                    <input type="text" class="form-control" id="viber" placeholder="Введите Viber" value="${feature.properties.viber || ''}">
                </div>
                <div class="mb-3">
                    <label for="telegram" class="form-label">Telegram</label>
                    <input type="text" class="form-control" id="telegram" placeholder="Введите Telegram" value="${feature.properties.telegram || ''}">
                </div>
                <div class="mb-3">
                    <label for="line" class="form-label">Line</label>
                    <input type="text" class="form-control" id="line" placeholder="Введите Line" value="${feature.properties.line || ''}">
                </div>
                <div class="mb-3">
                    <label for="event-type" class="form-label">Тип события</label>
                    <select class="form-control" id="event-type">
                        <option value="">Выберите тип события</option>
                    </select>
                </div>
                <div class="mb-3 row">
                    <div class="col-md-6">
                        <label for="event-start" class="form-label">Начало события</label>
                        <input type="datetime-local" class="form-control" id="event-start" value="${feature.properties.event_start || ''}">
                    </div>
                    <div class="col-md-6">
                        <label for="event-end" class="form-label">Окончание события</label>
                        <input type="datetime-local" class="form-control" id="event-end" value="${feature.properties.event_end || ''}">
                    </div>
                </div>
                <div class="mb-3">
                    <label for="website" class="form-label">Ссылка на сайт</label>
                    <input type="url" class="form-control" id="website" placeholder="Введите URL сайта" value="${feature.properties.website || ''}">
                </div>
                <div class="mb-3">
                    <label for="tags" class="form-label">Теги</label>
                    <select class="form-control" id="tags" multiple>
                        <!-- Добавить варианты тегов -->
                    </select>
                </div>
                <div class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" id="notify-users" ${feature.properties.notify_users ? 'checked' : ''}>
                    <label class="form-check-label" for="notify-users">
                        Уведомление пользователям
                    </label>
                </div>
                <button type="submit" class="btn btn-primary">Сохранить</button>
            </form>
        </div>
    `;

    setSidebarContent(formHTML);
    showSidebar(); // Открываем сайдбар

    // Загружаем типы событий и заполняем select
    await populateEventTypes();
    await populateTags();

    // Добавляем обработчик отправки формы
    const editEvent = document.getElementById('edit-marker-form');
    if (editEvent) {
        editEvent.addEventListener('submit', async function (event) {
            event.preventDefault();

            // Собираем данные из формы
            const newName = document.getElementById('event-name').value.trim();
            const newDescription = document.getElementById('marker-description').value.trim();
            const newAddress = document.getElementById('address').value.trim();
            const newContactNumber = document.getElementById('contact-number').value.trim();
            const newWhatsApp = document.getElementById('whatsapp').value.trim();
            const newViber = document.getElementById('viber').value.trim();
            const newTelegram = document.getElementById('telegram').value.trim();
            const newLine = document.getElementById('line').value.trim();
            const eventTypeId = document.getElementById('event-type').value;
            const eventStart = document.getElementById('event-start').value;
            const eventEnd = document.getElementById('event-end').value;
            const newWebsite = document.getElementById('website').value.trim();
            const selectedTags = Array.from(document.getElementById('tags').selectedOptions).map(option => option.value);
            const notifyUsers = document.getElementById('notify-users').checked;

            // Получаем файлы логотипа и изображений
            const logoInput = document.getElementById('logo');
            const logo = logoInput.files[0];

            const imageInput = document.getElementById('images');
            const images = Array.from(imageInput.files);

            // Проверка обязательных полей
            if (!newName || !newDescription || !newAddress || !newContactNumber || !eventTypeId || !eventStart || !eventEnd) {
                alert('Пожалуйста, заполните все обязательные поля.');
                return;
            }

            // Обновляем свойства фичи (если у тебя есть логика по обновлению карты)
            let featureUpdated = false;
            for (let feat of placesData.features) {
                feat.properties.name = newName;
                feat.properties.description = newDescription;
                feat.properties.address = newAddress;
                feat.properties.contact_number = newContactNumber;
                feat.properties.whatsapp = newWhatsApp;
                feat.properties.viber = newViber;
                feat.properties.telegram = newTelegram;
                feat.properties.line = newLine;
                feat.properties.event_type = eventTypeId;
                feat.properties.event_start = eventStart;
                feat.properties.event_end = eventEnd;
                feat.properties.website = newWebsite;
                feat.properties.tags = selectedTags;
                feat.properties.notify_users = notifyUsers;
                featureUpdated = true;
                break;
            }

            if (featureUpdated) {
                map.getSource('places').setData(placesData);
                // TODO сохранять в базу, а не в локальное
                localStorage.setItem('placesData', JSON.stringify(placesData));

                await createEvent({
                    name: newName,
                    description: newDescription,
                    images: images, // Передаем массив изображений
                    logo: logo, // Передаем логотип
                    markerType: 1,
                    eventType: eventTypeId,
                    startDt: eventStart,
                    endDt: eventEnd,
                    notificationEnabled: notifyUsers,
                    tags: selectedTags,
                    contactInfo: {
                        phoneNumbers: [newContactNumber] ? [newContactNumber] : [],
                        whatsappUrls: newWhatsApp ? [newWhatsApp] : [],
                        viberUrls: newViber ? [newViber] : [],
                        telegramUrls: newTelegram ? [newTelegram] : [],
                        lineUrls: newLine ? [newLine] : [],
                        websites: newWebsite ? [newWebsite] : [],
                    },
                    coordinates: feature.geometry.coordinates,
                });
            }

            hideSidebar();
        });

    }
}

// Думаю нуждается в доработке, как минимум фронт, думаю данные тоже не все передаются, потестить короче
export async function openEditPoint(feature) {

    console.log(feature);
    console.log(feature.properties)
    console.log(feature.properties.id)

    const isEdit = feature.properties.id != null;
    const contactInfo = feature.properties.contact_info ? JSON.parse(feature.properties.contact_info) : {};
    const workingHours = feature.properties.working_hours ? JSON.parse(feature.properties.working_hours) : {};
    const hasWorkingHours = Object.keys(workingHours).some(key => key !== 'id' && workingHours[key] !== null && workingHours[key] !== undefined);
    const imagesArray = feature.properties.images ? JSON.parse(feature.properties.images) : [];
    console.log(feature.properties.contact_info?.phone_numbers)

    const formHTML = `
        <div class="container">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <h3>${isEdit ? 'Редактировать точку' : 'Создать точку'}</h3>
            </div>
            <form id="edit-point-form" enctype="multipart/form-data">
                <div class="mb-3">
                    <label for="point-name" class="form-label">Название точки</label>
                    <input type="text" class="form-control" id="point-name" required placeholder="Введите название точки" 
                        value="${feature.properties.name || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="logo" class="form-label">Логотип точки</label>
                    <input type="file" class="form-control" id="logo">
                </div>
                
                ${imagesArray.length > 0 ? `
                    <div class="mb-3">
                        <label class="form-label">Текущее изображение:</label>
                        ${imagesArray.map(img => `
                            <img src="${API_URL}${img.image}" class="img-fluid mb-2" alt="Current Image" style="max-height: 150px;">
                        `).join('')}
                    </div>
                ` : ''}
                
                <div class="mb-3">
                    <label for="images" class="form-label">Изображения точки</label>
                    <input type="file" class="form-control" id="images" multiple>
                </div>
                <div class="mb-3">
                    <label for="point-description" class="form-label">Описание</label>
                    <textarea class="form-control" id="point-description" rows="3">${feature.properties.description || ''}</textarea>
                </div>
                <div class="mb-3">
                    <label for="address" class="form-label">Адрес</label>
                    <input type="text" class="form-control" id="address" placeholder="Введите адрес" 
                        value="${feature.properties.address || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="contact-number" class="form-label">Контактный номер</label>
                    <input type="text" class="form-control" id="contact-number" placeholder="Введите контактный номер" 
                        value="${(contactInfo.phone_numbers || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="whatsapp" class="form-label">WhatsApp</label>
                    <input type="text" class="form-control" id="whatsapp" placeholder="Введите WhatsApp" 
                        value="${(contactInfo.whatsapp_urls || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="viber" class="form-label">Viber</label>
                    <input type="text" class="form-control" id="viber" placeholder="Введите Viber" 
                        value="${(contactInfo.viber_urls || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="telegram" class="form-label">Telegram</label>
                    <input type="text" class="form-control" id="telegram" placeholder="Введите Telegram" 
                        value="${(contactInfo.telegram_urls || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="line" class="form-label">Line</label>
                    <input type="text" class="form-control" id="line" placeholder="Введите Line" 
                        value="${(contactInfo.line_urls || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="website" class="form-label">Ссылка на сайт</label>
                    <input type="url" class="form-control" id="website" placeholder="Введите URL сайта" 
                        value="${feature.properties.website || ''}"
                    >
                </div>

                <div class="mb-3">
                    <label for="working-hours-btn" class="form-label">Часы работы</label>
                    <button type="button" class="btn btn-outline-primary" id="working-hours-btn">Заполнить график работы</button>
                </div>
                <div class="mb-3">
                    <label for="tags" class="form-label">Теги</label>
                    <select class="form-control" id="tags" multiple>
                        <!-- Добавить варианты тегов -->
                    </select>
                </div>
                <div id="working-hours-section" style="display: ${hasWorkingHours ? 'block' : 'none'};">
                    <!-- Форма для заполнения графика работы -->
                    ${['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => `
                        <div class="row mb-3">
                            <div class="col-6">
                                <label for="${day}-open" class="form-label">${day.charAt(0).toUpperCase() + day.slice(1)} - Открытие</label>
                                <input type="time" class="form-control" id="${day}-open" value="${workingHours?.[`${day}_open`] || ''}">
                            </div>
                            <div class="col-6">
                                <label for="${day}-close" class="form-label">${day.charAt(0).toUpperCase() + day.slice(1)} - Закрытие</label>
                                <input type="time" class="form-control" id="${day}-close" value="${workingHours?.[`${day}_close`] || ''}">
                            </div>
                        </div>
                    `).join('')}
                </div>

                <div class="mb-3">
                    <label for="price-list-btn" class="form-label">Прайс-лист</label>
                    <button type="button" class="btn btn-outline-primary" id="price-list-btn">Добавить товар</button>
                </div>
                <div id="price-list-section">
                    ${feature.properties.price_list?.map((item, index) => `
                        <div class="row mb-3" data-index="${index}">
                            <div class="col-4">
                                <input type="text" class="form-control" placeholder="Название товара" value="${item.product_name}">
                            </div>
                            <div class="col-3">
                                <input type="number" step="0.01" class="form-control" placeholder="Цена" value="${item.price}">
                            </div>
                            <div class="col-2">
                                <input type="text" class="form-control" placeholder="Количество" value="${item.quantity}">
                            </div>
                            <div class="col-3">
                                <input type="text" class="form-control" placeholder="Валюта" value="${item.currency}">
                            </div>
                        </div>
                    `).join('') || ''}
                </div>
                
                <div class="mb-3">
                    <label for="zoom-slider" class="form-label">Масштаб иконки (диапазон: -10 до 10)</label>
                    <input type="range" class="form-control-range" id="zoom-slider" name="zoom" 
                        min="-10" max="10" step="0.1" value="${feature.properties.zoom || 1}"
                    >
                    <span id="zoom-value">${feature.properties.zoom || 1}</span>
                </div>

                <button type="submit" class="btn btn-primary">${isEdit ? 'Сохранить' : 'Добавить'}</button>
                ${isEdit ? `
                    <button type="button" class="btn btn-danger" id="delete-point-btn">Удалить</button>
                ` : ''}
            </form>
        </div>
    `;

    setSidebarContent(formHTML);
    showSidebar();

    await populateTags();

    const selectedTags = feature.properties.tags ? JSON.parse(feature.properties.tags) : [];

    const tagsSelect = document.getElementById('tags');
    selectedTags.forEach(tagName => {
        const option = Array.from(tagsSelect.options).find(opt => opt.text === tagName);
        if (option) option.selected = true;
    });

    // Добавляем обработчики для кнопок
    document.getElementById('working-hours-btn').addEventListener('click', () => {
        document.getElementById('working-hours-section').style.display = 'block';
    });

    document.getElementById('price-list-btn').addEventListener('click', () => {
        const priceListSection = document.getElementById('price-list-section');
        const newProductIndex = priceListSection.children.length;
        const newProductHTML = `
            <div class="row mb-3" data-index="${newProductIndex}">
                <div class="col-4">
                    <input type="text" class="form-control" placeholder="Название товара">
                </div>
                <div class="col-3">
                    <input type="number" step="0.01" class="form-control" placeholder="Цена">
                </div>
                <div class="col-2">
                    <input type="text" class="form-control" placeholder="Количество">
                </div>
                <div class="col-3">
                    <input type="text" class="form-control" placeholder="Валюта">
                </div>
            </div>
        `;
        priceListSection.insertAdjacentHTML('beforeend', newProductHTML);
    });

    const zoomSlider = document.getElementById('zoom-slider');
    if (zoomSlider) {
        zoomSlider.addEventListener('input', function () {
            document.getElementById('zoom-value').textContent = this.value;
        });
        feature.properties.zoom = parseFloat(this.value);
    }

    // удаление Точки
    if (isEdit) {
        document.getElementById(
            'delete-point-btn'
        ).addEventListener('click', async () => {
            console.log(feature.properties.id)
            const pointId = feature.properties.id; // Предполагается, что `feature` доступен в данном контексте
            if (confirm('Вы уверены, что хотите удалить эту точку?')) {
                try {
                    await deletePoint(pointId);
                    // Удаляем фичу из placesData
                    console.log(placesData)
                    placesData.features = placesData.features.filter(feat => feat.properties.id !== pointId);
                    console.log(placesData)
                    map.getSource('places').setData(placesData);
                    // Закрываем сайдбар
                    hideSidebar();
                    alert('Точка успешно удалена.');
                } catch (error) {
                    console.error('Ошибка при удалении точки:', error);
                    alert('Произошла ошибка при удалении точки.');
                }
            }
        });
    }

    // Обработчик формы
    const editPoint = document.getElementById('edit-point-form')
    if (editPoint) {
        editPoint.addEventListener('submit', async function (event) {
            event.preventDefault();

            const selectedTags = Array.from(document.getElementById('tags').selectedOptions).map(option => option.value);

            const logoInput = document.getElementById('logo');
            const logo = logoInput.files[0];

            const imageInput = document.getElementById('images');
            const images = Array.from(imageInput.files);

            const newContactNumber = document.getElementById('contact-number').value.trim();
            const newWhatsApp = document.getElementById('whatsapp').value.trim();
            const newViber = document.getElementById('viber').value.trim();
            const newTelegram = document.getElementById('telegram').value.trim();
            const newLine = document.getElementById('line').value.trim();
            const newWebsite = document.getElementById('website').value.trim();

            const newName = document.getElementById('point-name').value.trim();
            const newDescription = document.getElementById('point-description').value.trim();
            // TODO юзается вообще?
            const newAddress = document.getElementById('address').value.trim();

            const updatedPointData = {
                name: newName,
                description: newDescription,
                address: newAddress,
                images: images,
                logo: logo,
                // TODO выбирать с фронта и заносить нужное
                markerType: 3,
                category: feature.properties.category,
                subcategory: feature.properties.subcategory,
                tags: selectedTags,
                contactInfo: {
                    phoneNumbers: newContactNumber !== '' ? [newContactNumber] : [],
                    whatsappUrls: newWhatsApp ? [newWhatsApp] : [],
                    viberUrls: newViber ? [newViber] : [],
                    telegramUrls: newTelegram ? [newTelegram] : [],
                    lineUrls: newLine ? [newLine] : [],
                    websites: newWebsite ? [newWebsite] : [],
                },
                workingHours: {
                    mondayOpen: document.getElementById('monday-open').value,
                    mondayClose: document.getElementById('monday-close').value,
                    tuesdayOpen: document.getElementById('tuesday-open').value,
                    tuesdayClose: document.getElementById('tuesday-close').value,
                    wednesdayOpen: document.getElementById('wednesday-open').value,
                    wednesdayClose: document.getElementById('wednesday-close').value,
                    thursdayOpen: document.getElementById('thursday-open').value,
                    thursdayClose: document.getElementById('thursday-close').value,
                    fridayOpen: document.getElementById('friday-open').value,
                    fridayClose: document.getElementById('friday-close').value,
                    saturdayOpen: document.getElementById('saturday-open').value,
                    saturdayClose: document.getElementById('saturday-close').value,
                    sundayOpen: document.getElementById('sunday-open').value,
                    sundayClose: document.getElementById('sunday-close').value,
                },
                priceList: Array.from(document.getElementById('price-list-section').children).map(row => ({
                    product_name: row.querySelector('input[placeholder="Название товара"]').value,
                    price: parseFloat(row.querySelector('input[placeholder="Цена"]').value),
                    quantity: row.querySelector('input[placeholder="Количество"]').value,
                    currency: row.querySelector('input[placeholder="Валюта"]').value
                })),
                coordinates: feature.geometry.coordinates,
                zoom: feature.properties.zoom
            };

            try {
                let responsePoint;
                if (isEdit) {
                    // Обновляем существующую точку на сервере
                    responsePoint = await updatePoint(feature.properties.id, updatedPointData);

                    // Обновляем существующую точку в placesData
                    placesData.features = placesData.features.map(feat =>
                        feat.properties.id === feature.properties.id ? responsePoint : feat
                    );

                    // TODO пока так потому что че то не подтягивалось
                    placesData = await loadPlacesData();
                } else {
                    // Создаем новую точку на сервере
                    responsePoint = await createPoint(updatedPointData);

                    // Добавляем новую точку в placesData
                    const newPlacesData = addNewPlaceSingle(placesData, responsePoint);
                    placesData = newPlacesData; // Обновляем глобальную переменную

                    // TODO пока так потому что че то не подтягивалось
                    placesData = await loadPlacesData();
                }

                // Добавляем созданную точку в placesData
                // placesData.features.push(createdPoint);
                // map.getSource('places').setData(placesData);

                updatePlacesLayerData(map, placesData);
                // TODO потестить с глобальной переменной
                // updatePlacesLayerData(map, placesData);

                hideSidebar();
                alert(isEdit ? 'Точка успешно обновлена.' : 'Точка успешно создана.');
            } catch (error) {
                console.error(isEdit ? 'Ошибка при обновлении точки:' : 'Ошибка при создании точки:', error);
                alert(isEdit ? 'Произошла ошибка при обновлении точки.' : 'Произошла ошибка при создании точки.');
            }
        }
    );
}}


// Функция для загрузки начального контента в сайдбар
export function loadDefaultSidebarContent() {
    loadMainMenu();
}

// Добавление слоя на карту при загрузке
map.on('load', async () => {
    // Загрузка всех изображений
    loadAllMapImages(map, async () => {
        // Добавляем контролы на карту
        addNavigationControl(map);
        addScaleControl(map);
        addFullscreenControl(map);

        placesData = await loadPlacesData(); // Загружаем данные
        console.log('чисто загрузили точки с сервака', placesData);

        // Если данных нет, используем данные по умолчанию
        if (placesData.features.length === 0) {
            const defaultData = getDefaultPlacesData();
            // currentFeature = createEmptyEvent();
            addNewPlaces(placesData, defaultData);
            console.log('Используем данные по умолчанию:', defaultData);
        }

        addPlacesLayer(map, placesData);  // Добавление слоя с маркерами-кругами
        console.log('Слой с местами добавлен на карту');

        // Активация кликов на карте для добавления новых меток
        setupMapClickEvent(map);

        // Добавление интерактивных функций
        addInteractiveFunctions(map);

        // Загрузка начального контента в сайдбар
        loadDefaultSidebarContent();
    });
});

// Обработчики событий после загрузки DOM
document.addEventListener('DOMContentLoaded', function () {

    const sidebar = document.getElementById('sidebar');
    const toggleBtn = document.getElementById('toggle-sidebar');
    const mainCloseBtn = sidebar.querySelector('.btn-close');
    const adminPanelBtn = document.getElementById('admin-panel-btn');
    const adminToggleBtn = document.getElementById('admin-toggle-btn');

    // Обработчик для основной кнопки закрытия
    mainCloseBtn.addEventListener('click', function () {
        hideSidebar();
    });

    // Обработчик для кнопки toggle
    toggleBtn.addEventListener('click', function () {
        showSidebar();
    });

    // Инициализация состояния кнопки toggle
    if (sidebar.classList.contains('sidebar-hide')) {
        toggleBtn.classList.add('toggle-visible');
        toggleBtn.classList.remove('toggle-hidden');
        adminToggleBtn.classList.add('toggle-visible');
        adminToggleBtn.classList.remove('toggle-hidden');
    } else {
        toggleBtn.classList.add('toggle-hidden');
        toggleBtn.classList.remove('toggle-visible');
        adminToggleBtn.classList.add('toggle-hidden');
        adminToggleBtn.classList.remove('toggle-visible');
    }

    // Добавление делегированного обработчика для всех кнопок внутри сайдбара
    sidebar.addEventListener('click', function (event) {
        console.log("Клик внутри сайдбара"); // Отладочное сообщение

        if (event.target.closest('.btn-close')) {
            console.log("Нажата кнопка закрытия"); // Отладочное сообщение
            hideSidebar();
        }

        if (event.target.closest('.btn-main-menu')) {
            console.log("Нажата кнопка главного меню"); // Отладочное сообщение
            loadMainMenu();
        }

        // Обработчики для кнопок главного меню
        if (event.target.id === 'categories-btn') {
            console.log("Нажата кнопка Категории"); // Отладочное сообщение
            loadCategoriesMenu();
        }

        if (event.target.id === 'create-event-btn') {
            currentFeature = createEmptyEvent();
            // Логика для кнопки "Создание событий"
            showEditForm(currentFeature);
        }
    });

    function openAdminPanel() {
        const newWindow = window.open('https://api.tap-map.net/admin/', '_blank');
        if (newWindow) {
            newWindow.focus(); // Фокусируемся на новой вкладке
        } else {
            alert("Ваш браузер заблокировал всплывающее окно. Пожалуйста, разрешите его для этого сайта.");
        }
        console.log('Переход в Админ панель');
    }

    if (adminToggleBtn) {
        adminToggleBtn.addEventListener('click', openAdminPanel);
    }

    if (adminPanelBtn) {
        adminPanelBtn.addEventListener('click', openAdminPanel);
    }
});


// Админка
function openAdminPanel() {
    window.open('https://api.tap-map.net/admin/', '_blank', 'noopener,noreferrer');
}
