import {API_ADMIN_TOKEN, API_URL} from './config';


const EVENTS_URL_SUFFIX = '/api/events/'
// TODO переназвать на беке /events/types/
const EVENT_TYPES_URL_SUFFIX = '/api/event_types/'

const EVENT_TYPES_URL = `${API_URL}${EVENT_TYPES_URL_SUFFIX}`

// Функция для загрузки конкретного события
export async function populateEvent(eventId) {
    try {
        const response = await fetch(`${API_URL}/api/events/${eventId}/`);

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        const event = await response.json();

        document.getElementById('event-name').value = event.properties.name;
        document.getElementById('event-description').value = event.properties.description;
        document.getElementById('event-start').value = event.properties.start_dt;
        document.getElementById('event-end').value = event.properties.end_dt;

        // Обработка тегов
        const tagsSelect = document.getElementById('tags');
        tagsSelect.innerHTML = '<option value="">Выберите тег</option>';
        event.properties.tags.forEach(tag => {
            const option = document.createElement('option');
            option.value = tag; // Вы можете изменить значение в зависимости от структуры данных
            option.textContent = tag;
            tagsSelect.appendChild(option);
        });

        const contactInfo = event.properties.contact_info;
        if (contactInfo) {
            document.getElementById('contact-phone').value = contactInfo.phone_numbers.join(', ');
            document.getElementById('whatsapp-urls').value = contactInfo.whatsapp_urls.join(', ');
            document.getElementById('viber-urls').value = contactInfo.viber_urls.join(', ');
            document.getElementById('telegram-urls').value = contactInfo.telegram_urls.join(', ');
            document.getElementById('line-urls').value = contactInfo.line_urls.join(', ');
            document.getElementById('whatsapp-urls').value = contactInfo.websites.join(', ');
        }

        // Если нужно отобразить изображение
        const eventImage = document.getElementById('event-image');
        eventImage.src = event.properties.image;

    } catch (error) {
        console.error('Ошибка загрузки события:', error);
    }
}

// Функция для загрузки типов событий
export async function populateEventTypes() {
    try {
        const response = await fetch(EVENT_TYPES_URL);
        const eventTypes = await response.json();

        const eventTypeSelect = document.getElementById('event-type');

        // Очищаем select перед добавлением новых опций
        eventTypeSelect.innerHTML = '<option value="">Выберите тип события</option>';

        // Добавляем каждый тип события в виде опции
        eventTypes.forEach(eventType => {
            const option = document.createElement('option');
            option.value = eventType.id;
            option.textContent = eventType.name;
            eventTypeSelect.appendChild(option);
        });
    } catch (error) {
        console.error('Ошибка загрузки типов событий:', error);
    }
}

// Функция для создания Event'а
export async function createEvent(eventData) {

    const url = `${API_URL}/api/events/`;

    // Создаем payload для JSON-данных
    const payload = {
        type: "Feature",
        properties: {
            name: eventData.name,
            description: eventData.description,
            marker_type: eventData.markerType,
            type: eventData.eventType,
            start_dt: eventData.startDt,
            end_dt: eventData.endDt,
            notification_enabled: eventData.notificationEnabled,
            tags: eventData.tags,
            contact_info: {
                phone_numbers: eventData.contactInfo.phoneNumbers || [],
                whatsapp_urls: eventData.contactInfo.whatsappUrls || [],
                viber_urls: eventData.contactInfo.viberUrls || [],
                telegram_urls: eventData.contactInfo.telegramUrls || [],
                line_urls: eventData.contactInfo.lineUrls || [],
                websites: eventData.contactInfo.websites || [],
            }
        },
        geometry: {
            type: "Point",
            coordinates: eventData.coordinates
        }
    };

    try {
        // Отправляем JSON-данные
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }

        const responseData = await response.json();
        console.log('Событие успешно создано:', responseData);

        // Получаем ID созданного события
        const eventId = responseData.properties.id;

        // После успешного создания события, отправляем файлы
        if (eventData.logo || eventData.images.length > 0) {
            const formData = new FormData();

            if (eventData.logo) {
                formData.append('logo', eventData.logo);
            }

            if (eventData.images.length > 0) {
                eventData.images.forEach(image => {
                    formData.append('images', image); // здесь добавляем просто сам файл
                });
            }

            // Отправляем файлы
            const fileResponse = await fetch(`${url}${eventId}/`, {
                method: 'PATCH', // Используем PATCH для обновления
                headers: {
                    'Authorization': `Token ${API_ADMIN_TOKEN}`,
                },
                body: formData
            });

            if (!fileResponse.ok) {
                throw new Error('Ошибка при загрузке файлов: ' + fileResponse.statusText);
            }

            const fileResponseData = await fileResponse.json();
            console.log('Файлы успешно загружены:', fileResponseData);
        }

    } catch (error) {
        console.error('Ошибка при создании события:', error);
    }
}




