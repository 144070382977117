import {API_URL} from "./config";

export async function getMarkerTypes() {
    try {
        const response = await fetch(`${API_URL}/api/marker_types/`);
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching marker types:', error);
        return [];
    }
}
