export const placeLayerId = 'places';
const placeLayerSourceId = placeLayerId;


export function addPlacesLayer(map, data) {
    if (map.getSource(placeLayerId)) {
        map.getSource(placeLayerId).setData(data);
    } else {
        map.addSource(placeLayerId, {
            type: 'geojson',
            data: data
        });

        _addPlacesLayer(map);
    }
}

// Функция для добавления пользовательских маркеров (например, изображений)
export function _addPlacesLayer(map) {
    // Добавляем слой для маркеров
    map.addLayer({
        'id': placeLayerId,
        'type': 'symbol',
        'source': placeLayerId, // Используйте placeLayerId для источника
        'layout': {
            'icon-image': ['get', 'marker_type'],  // Получение иконки из свойства маркера
            'icon-allow-overlap': true,  // Иконки могут перекрываться
        }
    });

    // Добавляем слой для отображения имен точек
    if (!map.getLayer('places-name-layer')) {
        map.addLayer({
            'id': 'places-name-layer',
            'type': 'symbol',
            'source': placeLayerId, // Используйте тот же источник
            'layout': {
                'text-field': ['get', 'name'],  // Использует поле "name" из GeoJSON
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                'text-size': 14,
                'text-offset': [0, 0.7], // Смещение текста под маркером
                'text-anchor': 'top' // Позиционируем текст над маркером
            },
            'paint': {
                'text-color': '#000000' // Цвет текста
            }
        });
    }
}


// Функция для обновления слоя при изменении данных
export function updatePlacesLayerData(map, newData) {
    const source = map.getSource(placeLayerId);
    if (source) {
        source.setData(newData);  // Обновление данных источника
    } else {
        console.error(`Источник данных для слоя ${placeLayerId} не найден`);
    }
}

// Функция для управления видимостью слоя
export function togglePlacesLayerVisibility(map, isVisible) {
    const visibility = isVisible ? 'visible' : 'none';
    map.setLayoutProperty(placeLayerId, 'visibility', visibility);
}
