import {API_URL} from './config';


const CATEGORIES_URL_SUFFIX = '/api/categories/'
const CATEGORIES_URL = `${API_URL}${CATEGORIES_URL_SUFFIX}`


// Функция для загрузки категорий
export async function populateCategories() {
    try {
        const response = await fetch(CATEGORIES_URL);
        return await response.json(); // Возвращаем список категорий с подкатегориями
    } catch (error) {
        console.error('Ошибка загрузки категорий и подкатегорий:', error);
        return [];
    }
}