import mapboxgl from 'mapbox-gl';
import {placeLayerId} from './layers';
import {openEditPoint} from './../index'; // Импортируем функцию openEditForm

// Функция для создания и обновления попапа
export function updatePopup(popup, coordinates, id, name, description, image) {
    const popupContent = `
        <div class="popup-content">
            <div class="card" style="width: 18rem; max-width: 100%;">
                ${image ? `<img src="${image}" class="card-img-top img-fluid" alt="Image" style="object-fit: cover; max-height: 150px;">` : ''}
                <div class="card-body">
                    <h5 class="card-title">ID: ${id}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">${name}</h6>
                    <p class="card-text">${description}</p>
                </div>
            </div>
        </div>
    `;

    popup.setLngLat(coordinates).setHTML(popupContent);
}

// Функция для добавления попапа на карту
export function addPopupToMap(map, popup, coordinates, id, name, description, image) {
    updatePopup(popup, coordinates, id, name, description, image);
    if (!popup.isOpen()) {
        popup.addTo(map);
    }
}

// Функция для удаления попапа
export function removePopup(popup) {
    popup.remove();
}

// Оставляем только одну функцию showEditForm
export function showEditForm(feature) {
    // Вызов экспортированной функции openEditForm
    openEditPoint(feature);
}

// Функция для добавления интерактивных функций на карту
export function addInteractiveFunctions(map) {
    // Создание пустого попапа
    const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
    });

    // Обработчик наведения на метку
    map.on('mouseenter', placeLayerId, function (e) {
        map.getCanvas().style.cursor = 'pointer';

        const feature = e.features[0];
        const coordinates = feature.geometry.coordinates.slice();
        const id = feature.properties.id;
        const name = feature.properties.name;
        const description = feature.properties.description;
        const image = feature.properties.image;

        // Корректировка координат
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        addPopupToMap(map, popup, coordinates, id, name, description, image);
    });

    // Обработчик ухода курсора с метки
    map.on('mouseleave', placeLayerId, function () {
        map.getCanvas().style.cursor = '';
        removePopup(popup);
    });

    // Обработчик клика на метку для открытия формы редактирования
    map.on('click', placeLayerId, function (e) {
        console.log(e.features);
        console.log(e.features[0]);
        const feature = e.features[0];
        showEditForm(feature);
    });
}
