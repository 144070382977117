import {API_URL} from './config';


const TAGS_URL_SUFFIX = '/api/tags/'
const TAGS_URL = `${API_URL}${TAGS_URL_SUFFIX}`


// Функция для загрузки тегов событий
export async function populateTags() {
    try {
        const response = await fetch(TAGS_URL);
        const tags = await response.json();

        const tagsSelect = document.getElementById('tags');

        // TODO тестим без дефолтного тэга
        // tagsSelect.innerHTML = '<option value="">Выберите тег</option>';
        // Очищаем select перед добавлением новых опций
        tagsSelect.innerHTML = '';

        // Добавляем каждый тег в виде опции
        tags.forEach(tag => {
            const option = document.createElement('option');
            option.value = tag.id;
            option.textContent = tag.name;
            tagsSelect.appendChild(option);
        });
    } catch (error) {
        console.error('Ошибка загрузки тегов событий:', error);
    }
}