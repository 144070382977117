import 'mapbox-gl/dist/mapbox-gl.css';

const mapboxAccessToken = 'pk.eyJ1IjoibWFwMjN0cmF2ZWwiLCJhIjoiY20xMDV4eGJ5MGVhejJrc2dzOXUxZGM5bCJ9.AZdS5yS841WJ0uUbD6kE0g';
const mapboxStyleId = 'mapbox://styles/map23travel/cm106d9a801a601qo19ehandv';

export const layerUrl= 'mapbox://map23travel.4eqycuzb';
export const sourceLayer = 'features-b5mxon';

export const mapConfig = {
    accessToken: mapboxAccessToken,
    styleId: mapboxStyleId,
    center: [98.3923, 7.8804], // Пхукет
    zoom: 15,
    containerId: 'map'
};
